var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.educationalGroupTransfersData)?_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("#")]),_c('th',{attrs:{"colspan":"3"}},[_vm._v(" "+_vm._s(_vm.$t("students.data"))+" ")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t("EducationalGroupTransfers.oldGroup.data"))+" ")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t("EducationalGroupTransfers.newGroup.data"))+" ")]),_c('th',{staticClass:"cell-md",attrs:{"rowspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t("EducationalGroupTransfers.dateTime"))+" ")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v(_vm._s(_vm.$t("EducationalCategories.name")))]),_vm._m(0)]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("general.image")))]),_c('th',[_vm._v(_vm._s(_vm.$t("general.code")))]),_c('th',{staticClass:"cell-md"},[_vm._v(_vm._s(_vm.$t("general.name")))]),_c('th',[_vm._v(_vm._s(_vm.$t("general.code")))]),_c('th',{staticClass:"cell-md"},[_vm._v(_vm._s(_vm.$t("general.name")))]),_c('th',[_vm._v(_vm._s(_vm.$t("general.code")))]),_c('th',{staticClass:"cell-md"},[_vm._v(_vm._s(_vm.$t("general.name")))])])]),_c('tbody',_vm._l((_vm.educationalGroupTransfersData),function(educationalGroupTransfer,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(++index + _vm.filterData.currentIndex))]),_c('td',[_c('img',{staticClass:"item-img-table",attrs:{"src":_vm.fullPathFileFromServer(
                educationalGroupTransfer.userStudentInfoData
                  ? educationalGroupTransfer.userStudentInfoData.userImagePath
                  : '',
                _vm.userImg
              ),"onerror":("this.src='" + _vm.userImg + "'")}})]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist( educationalGroupTransfer.userStudentInfoData ? educationalGroupTransfer.userStudentInfoData.fullCode : "" ))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist( educationalGroupTransfer.userStudentInfoData ? educationalGroupTransfer.userStudentInfoData.userNameCurrent : "" ))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist( educationalGroupTransfer.oldEducationalGroupInfoData ? educationalGroupTransfer.oldEducationalGroupInfoData .fullCode : "" ))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist( educationalGroupTransfer.oldEducationalGroupInfoData ? educationalGroupTransfer.oldEducationalGroupInfoData .educationalGroupNameCurrent : "" ))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist( educationalGroupTransfer.newEducationalGroupInfoData ? educationalGroupTransfer.newEducationalGroupInfoData .fullCode : "" ))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist( educationalGroupTransfer.newEducationalGroupInfoData ? educationalGroupTransfer.newEducationalGroupInfoData .educationalGroupNameCurrent : "" ))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formateDateTimeLang( educationalGroupTransfer.educationalGroupTransferringDate, educationalGroupTransfer.educationalGroupTransferringTime ))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist( educationalGroupTransfer.educationalCategoryInfoData ? educationalGroupTransfer.educationalCategoryInfoData .educationalCategoryNameCurrent : "" ))+" ")]),_c('td',{staticClass:"fmenu-item-container"},[_c('FloatingMenu',[_c('li',[_c('button',{attrs:{"title":_vm.$t('info')},on:{"click":function($event){_vm.setEducationalGroupTransferData(educationalGroupTransfer);
                  _vm.openBottomSheet('EducationalGroupTransferInfo');}}},[_c('img',{attrs:{"src":require("@/assets/images/info.svg")}})])]),_c('li',[_c('button',{attrs:{"title":_vm.$t('actionsData.modelName')},on:{"click":function($event){_vm.setEducationalGroupTransferData(educationalGroupTransfer);
                  _vm.openBottomSheet('ActionsData');}}},[_c('img',{attrs:{"src":require("@/assets/images/actions-data.svg")}})])])])],1)])}),0)])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{attrs:{"rowspan":"2"}},[_c('i',{staticClass:"fas fa-sliders-h"})])}]

export { render, staticRenderFns }